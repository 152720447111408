























































































import { types } from '@truck-parking/tp-api';
import Vue from 'vue';
import Timer from '../components/Timer.vue';

export default Vue.extend({
  name: 'ParkingVue',
  components: { Timer },
  props: {
    warningTime: {
      type: Number,
      required: true,
    },
    mapImg: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      /** Current parking event or null (null initially & when there is no current parking event) */
      parkingEvent: null as types.ParkingEvent | null,
      /** Restroom code or null (null initially) */
      restroomCode: null as types.RestroomCode | null,
    };
  },
  computed: {
    startDate(): string | null {
      return this.parkingEvent ? this.parkingEvent.startDateTime : null;
    },
    expiryDate(): string | null {
      return this.parkingEvent ? this.parkingEvent.expiryDateTime : null;
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    /** Fetches the required data from the backend */
    fetchData(): void { // Fetch data from backend
      this.$api.getCurrentParkingEvent().then(({ body: event }) => {
        if (event === null) {
          // No current parking event – Redirect to welcome
          this.$toaster.warning(this.$i18n.t('parking-noEvent').toString());
          this.$replaceRoute('Welcome');
        }

        this.parkingEvent = event;
        this.loading = false;
      });

      this.$api.getRestroomCode().then((restroomCode) => {
        this.restroomCode = restroomCode;
      });
    },
    /** Called when `drive out now` button has been pressed */
    driveOut(): void {
      this.$changeRoute('DriveOut');
    },
  },
});
